@import url('./styles/metronic8.css'); 
.App {
  width: 100vw;
  height: 100vh;
  background: #fff;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  display: grid;
  place-items: center;
}
body {
  margin: 0%;
  padding: 0%;
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");
/*
.joinChatContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.joinChatContainer h3 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.joinChatContainer input {
  width: 210px;
  height: 40px;
  margin: 7px;
  border: 2px solid #43a047;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}


.joinChatContainer button {
  width: 225px;
  height: 50px;
  margin: 7px;
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  background: #43a047;
  color: #fff;
  cursor: pointer;
}

.joinChatContainer button:hover {
  background: #2e7d32;
}
*/
.chat-window {
  /*width: 300px;*/
  height: 420px;
}

.chat-window p {
  margin: 0;
}
.chat-window .chat-header {
  height: 45px;
  border-radius: 6px;
  background: #263238;
  position: relative;
  cursor: pointer;
}
.chat-window .chat-header p {
  display: block;
  padding: 0 1em 0 2em;
  color: #fff;
  font-weight: 700;
  line-height: 45px;
}

.chat-window .chat-body, .chat-max-height {
  height: calc(450px - (45px + 70px));
  /*border: 1px solid #263238;
  background: #fff;*/

  position: relative;
}
.chat-window .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-window .chat-body .message-container::-webkit-scrollbar {
  display: none;
}
.chat-window .chat-body .message {
  height: auto;
  padding: 10px;
  display: flex;
}

.chat-window .chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  max-width: 50vw;
  /*background-color: #43a047;*/
  background-color: #21262e;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 0px;
  padding:10px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-end;
}

#you .message-content {
  justify-content: flex-start;
  margin-right: 0px;
}

#you .message-meta {
  justify-content: flex-start;
  margin-left: 5px;
}

#other {
  justify-content: flex-start;
}

#other .message-content {
  margin-left: 0px;
  justify-content: flex-end;
  /*background-color: cornflowerblue;*/
  background-color: #292636;
}

#other .message-meta {
  justify-content: flex-end;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  font-size: 12px;
}

.chat-window .chat-footer {
  height: 40px;
  border: 1px solid #263238;
  border-top: none;
  display: flex;
}

.chat-window .chat-footer input {
  height: 100%;
  flex: 85%;
  border: 0;
  padding: 0 0.7em;
  font-size: 1em;
  border-right: 1px dotted #607d8b;

  outline: none;
  font-family: "Open Sans", sans-serif;
}

.chat-window .chat-footer button {
  border: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  flex: 15%;
  height: 100%;
  background: transparent;
  outline: none;
  font-size: 25px;
  color: lightgray;
}

.chat-window .chat-footer button:hover {
  color: #43a047;
}
.hide {
  opacity: 0 !important;
}


/* designer */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

[type='radio'] {
  opacity: 1;
}

.input-group {
  margin-top: 15px;
  margin-bottom: 15px;
}

input {
  flex-grow: 2;
  border: none;
  font-size: 16px;
}

input:focus {
  outline: none;
}
select {  background: transparent;  color: #fff;  border: none;  outline: none; }
select option { color: #000;}
/* Design Style Start here */
a,
a:link,
a:visited,
img,
div {
  text-decoration: none;
  outline: none;
}
.divider-0,
.divider-1,
.divider-2,
.divider-3,
.divider-4,
.divider-5,
.divider-6,
.divider-7,
.divider-8,
.divider-9 {
  clear: both;
  height: 10px;
}
.divider-0 {
  height:4px;
}
.divider-2 {
  height: 20px;
}

.divider-3 {
  height: 30px;
}

.divider-4 {
  height: 40px;
}

.divider-5 {
  height: 50px;
}

.divider-6 {
  height: 60px;
}

.divider-7 {
  height: 70px;
}

.divider-8 {
  height: 80px;
}

.divider-9 {
  height: 90px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

h2.page-title-1,
.page-title-1 {
  
  clear: both; color: #fff;
}

h2.page-sub-title-1,
.page-sub-title-1 {
  font-size: 16px;
  clear: both;
  font-weight:400;
}

.navbar-con-main {
  margin-bottom: 50px;
}

.middle-con-main-con {
  clear: both;
  float: left;
  width: 100%;
  margin-bottom: 100px;
}
.app-con-con-welcome {
  margin: 10px auto;
}
.app-con-con-welcome .icon-box {
  font-size: 2em; margin: 0px 20px;
}
.quick-support-side-con {
  position:fixed;
  right: 0; top:50vh;
  width:30px;
  padding:0px;
}
.quick-support-side-con a { display: block; margin:15px 0px;}
.quick-support-side-con .applogo{ height: 30px; width: auto;}
.footer-main-con {
  clear: both;
  float: left;
  width: 100%;
  color: #888;
  border-top: solid 10px #333;
  background: #000;
  padding-left: 15px;
  padding-right: 15px; padding: 50px; padding-bottom: 20px;
}
.footer-main-con a, .footer-main-con a:visited { color: #888!important;}
.footer-main-con a:hover, .footer-main-con a:focus { color: #fff !important;;}
.footer-main-con .footer-title {   font-weight: 400;  line-height: 1.2; }
.footer-colum {  margin-bottom: 20px; }
.footer-main-con .footer-colum a { clear: both; display: block;}
@media (max-width:1280px) {
  .footer-main-con .col-2,
  .footer-main-con .col-4 {
    display: flex;
    width: 50%;
    margin: 10px 0px;
    flex-direction: column;
  }

  .navbar-brand a img {
    max-width: 65vw;
  }

  .navbar-toggler {
    padding: 2px;
    font-size: 18px;
    background: #000000;
    color: #fff;
    outline: solid 1px #666;
  }

  .btn,
  .btn-lg {
    border-radius: 0.3rem;
    padding: 4px 10px;
    font-size: 1em;
    line-height: 22px;
  }
button,button:focus,  input, .btn, .btn:focus,
a, a:focus, a:link, .btn-check:focus+.btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
  box-shadow:none!important;
}
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal; font-weight: 400;
  }
  .home-call-action-con .btn {
    margin-top: 0px !important;
  }
}

@media (max-width:640px) {
  .footer-colum {
    width: 100%;
  }

  .footer-main-con .col-2,
  .footer-main-con .col-4 {
    display: flex;
    width: 100%;
    margin: 10px 0px;
    flex-direction: column;
  }

  .home-call-action-con span {
    display: block;
    width: 100%;
    font-size: 22px;
  }

  .home-call-action-con .btn {
    clear: both;
    margin-top: 10px !important;
  }
}

.footer-main-con .border-top {
  border-color: #333;
}

.form-main-con-1, .form-main-con-1.container {
  width: 100%;
  min-height: 60vh;
  max-width: 400px;
  margin: auto;
  padding: 50px 40px!important;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
}

.form-main-con-1 p {
  color: #666;
}

.homepage-main-bg-1 {
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  object-fit: cover;
  width: 100vw;
  height: 100vh;

}

body {
  background: #000 !important;
}

/*
body.page .navbar-con-main {
  background: rgba(0, 0, 0, 0.7);
}
.navbar-con-main {
  background: #fff;
}*/
.header-address-con { min-width:45%; text-align: center; color: #fff; font-size: 2em;}
.hometop-filter-menu-con { float: left; position: relative; margin:-100px 0 0; padding:0; list-style: none; display: flex; justify-content: space-between;  width: 70%;}
.hometop-filter-menu-con li { float: left; position: relative; display: flex;}
.hometop-filter-menu-con li .filtermenuicon {  width: 100px; height: 100px; background-color:#333; text-align: center; align-items: center; color:#fff; 
  border: solid 2px #686868; border-radius: 50%;     display: flex;  justify-content: center;  font-size: 2.5em;  color: #fff;}
  .hometop-filter-menu-con li .filtermenuicon:hover {background-color: #000;   transition: transform 0.5s;
    /* Animation */   cursor: pointer;}
.hometop-filter-menu-con li .filtermenu-label { clear: both;     position: absolute; width: 100%; text-align: center; color: #fff; bottom: -25px;}

.hometop-maincon {
  padding:30px 0px; position: relative;
  clear: both; float: left; width:100%; background-color: #333;
}
.hometop-maincon .hometop-usertype { position: relative; line-height:40px; top: 30px; font-size:10em; font-weight: 600; color: #383838; 
  font-family: 'Times New Roman', Times, serif;}
.hometop-filter-con { float: right;}
.homepage-main-con {
  margin: auto;
  position: relative;
  clear: both;
  float: left;
  width: 100%;
}

.homepage-main-con .home-inn-con-1 {
  top: 15vh;
  text-align: left;
  line-height: 50px;
}

.homepage-main-con .home-inn-con-1 h1, h1, .h1 {
  font-size: 36px;
  font-weight:400;
  margin: 0px auto 0px; padding-left: 0;
  text-transform: none;
}

.homepage-main-con .home-inn-con-1 h2,h2, .h2 {
  font-size: 36px;
    font-weight: 400;
    margin: 0px auto 0px;
    padding-left: 0;
    text-transform: none;
}

.homepage-main-con .home-inn-con-1 h3, h3, .h3 {
  font-size: 26px;
    font-weight: 400;
    margin: 0px auto 0px;
    padding-left: 0;
    text-transform: none;
}

.homepage-main-con .home-inn-con-1 * {
  color: #fff;
}

.homepage-main-con .btn-start,
.homepage-main-con .btn-start:hover,
.homepage-main-con .btn-start:focus {
  background: red;
  width: auto;
  padding: 15px 40px;
  line-height: normal;
  height: auto;
  margin: 50px auto;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  border: none;
  box-shadow: none;
  transform: none;
}

.homepage-main-con .btn-start:hover,
.homepage-main-con .btn-start:focus {
  background-color: rgb(187, 0, 0);
}

.home .navbar-light .navbar-toggler {
  color: #fff;
}
.breadcrumb .breadcrumb-item { cursor: pointer;     padding-right: 10px; margin-right: 15px; position: relative; }
.breadcrumb .breadcrumb-item:after { position: absolute; right: -5px; }
.booklisting-main-con { position: relative; padding-right: 100px;}
.booklisting-mainbook-con { position:absolute; right: 0; top:50px;}
.booklisting-mainbook-con img { max-width: 200px; height: auto;}
.book-chapterlist-con { clear: both; margin: 0; padding: 0; list-style: none;}
.book-chapterlist-con>li { /*list-style: inside decimal-leading-zero;*/ width: auto; clear: both; float: left; padding: 0; margin: 10px 0px; cursor: pointer;}
.book-chapterlist-con>li, .book-chapterlist-con a { color:#fff;}
.book-chapterlist-boxcon { clear: both; margin: 0; padding: 0; list-style: none; max-width: 90%;}
.book-chapterlist-boxcon-data { clear: both; margin: 0; padding: 0; list-style: none; max-width: 90%;}
.book-chapterlist-boxcon li, .book-chapterlist-boxcon .book-chap-tab { padding:0px; margin: 0px 0px 10px; width:20%; float: left; display: flex; justify-content: left; align-items: center;}
.book-chapterlist-boxcon li .book-chapter-inn, .book-chapterlist-boxcon .book-chap-tab .book-chapter-inn { color: #fff;   width:95%;  text-align: center; font-size:1.5em; position: relative; 
   padding-bottom:10px;  border-radius: 10px;  min-height: 100px; overflow: hidden; }
.book-chapterlist-boxcon li:hover .book-chapter-inn, .book-chapterlist-boxcon .book-chap-tab:hover .book-chapter-inn { transition: transform 0.5s; cursor: pointer;}
.book-chapterlist-boxcon .chaptericon { display: flex;  justify-content: center;  align-items: center;  width: auto; padding:0px 10px;  margin-bottom: 10px;}
.book-chapterlist-boxcon .chaptericon img { width:5vw; min-width:50px; height: auto; }
.book-chapterlist-boxcon .chaptername {color:#333; font-size:16px; font-weight: 600; display: flex;  justify-content: center; align-items: center;}
.book-chapterlist-boxcon .chaptercount {color:#333;  font-size: 0.7em;     line-height: normal; margin:5px; font-weight:400; display: flex;  justify-content: center; align-items: center;}
.book-chapterlist-boxcon .book-chapter-inn.nodata { opacity: 0.3; pointer-events: none; }
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.movie-row-inn-con::-webkit-scrollbar {
  height: 5px;
  scroll-behavior: smooth;
}

.movie-row-inn-con::-webkit-scrollbar-track {
  background: #333;
}

.movie-row-inn-con::-webkit-scrollbar-thumb {
  background: red;
  cursor: pointer;
}

.movie-row-inn-con {
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content:left;
  padding: 0px 0px 10px !important;
  margin: 10px auto;
  cursor:grab;
}

.movie-row-inn-con .movie-img,
.movie-row-inn-con .movie-img-large,
.movie-row-inn-con .movie-img-banner {
  margin: 0px 10px 0px 0px;
  height: auto;
  max-height:500px;
  width: 300px;
  min-width:200px;
  object-fit: contain;
  transition: transform 0.5s;
  /* Animation */
  cursor: pointer;
}

.movie-row-inn-con .movie-img-large {
  max-height: 500px;
  width: 100%;
}

.movie-row-inn-con .movie-img:hover {
  transform: scale(1.06);
}

.movie-banner-con-main {
  height: 400px;
  position: relative;
  overflow: hidden;
}

.movie-banner-con-main .movie-img-banner {
  width: 100%;
  max-width: 100%;
  max-height: 1000px;
  position: relative;
  overflow: hidden;
}

.movie-banner-con-main .banner-con-box {
  position: absolute;
  z-index: 99;
  color: #fff;
  font-size: 72px;
  text-transform: uppercase;
  letter-spacing: 10px;
  height: 400px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  transition: transform 0.4s;
  opacity: 0.4;
  cursor: pointer;
}

.movie-banner-con-main .banner-con-box:hover {
  opacity: 1;
}

.movie-banner-con-main:hover img {
  transform: scale(1.06);
  transition: transform 0.4s;
}

.movie-banner-con-main img {
  position: relative;
  z-index: 0;
}
.search-filter-button {  position: absolute;   top: 0px;  right:0px; border-radius:0px;
  background: #222;   padding:10px 20px;   bottom: 0; cursor: pointer;}
.search-filter-options { display: none; margin:2px auto 0 auto; padding:0px 0px; float:left; width: 75%; }
.search-filter-options .search-filter-options.show { opacity: 1;  }
.search-filter-options .search-filter-options.hide { opacity: 0;}
.search-filter-options .nobtn { background: #000; color: #fff; width: 18%; padding: 15px 0px; margin:2px 0px 2px 2%;}
/*
.homefreeactivity {margin:30px auto 0px; display: block;  clear: both;  float: left; width: 100%; }
*/


.homefreeactivity .act-box-main-con .act-name { min-height:65px; }
.homefreeactivity .act-box-main-con .act-rat-icon-con { margin:5px 2%; width:28%; padding:10px; text-align: center; float: left; cursor:pointer; border-radius: 0.3rem;}
.homefreeactivity .act-box-main-con .act-rat-icon-con.text-yellow2 { color: #fff; background-color:#dcb322 ;}
.homefreeactivity .act-box-main-con .act-rat-icon-con.text-blue2 { color: #fff; background-color: #42bcd4;}
.homefreeactivity .act-box-main-con .act-rat-icon-con.text-pink { color: #fff; background-color: #f05c63;}

.homefreeactivity .act-box-main-con .act-rat-icon-con.text-yellow2 *,
.homefreeactivity .act-box-main-con .act-rat-icon-con.text-blue2 *,
.homefreeactivity .act-box-main-con .act-rat-icon-con.text-pink * { color: #fff; }
.faq-con-main-home-con {   clear: both;  float: left;  width: 100%;  padding:80px 0px;  margin: 50px auto 0px auto;  display: block;  background: #000; }
.home-free-act-nobg { background: none; padding: 0px; margin: 0px auto;}
.home-free-act-nobg h1.text-white { padding-left:10px;}


.faqpage .faq-con-main {
  padding: 0px;
  margin: 0px;
}

.faq-con-main .accordion-item {
  background: #303030;
  padding: 0px;
  margin: 0px auto 10px;
  box-shadow: none;
}

.faq-con-main .accordion-header,
.faq-con-main .accordion-button:focus,
.faq-con-main .accordion-button,
.faq-con-main .accordion-button:not(.collapsed),
.faq-con-main .accordion-body {
  color: #fff;
  margin: 0px auto;
  background: none;
  box-shadow: none;
  text-align: left;
  border-bottom: solid 1px transparent;
}

.faq-con-main .accordion-button:not(.collapsed) {
  border-bottom-color: #1a1919;
}

.faq-con-main .accordion-button::after,
.faq-con-main .accordion-button:not(.collapsed)::after {
  color: #fff;
  background: none;
  content: '+';
  line-height: 14px;
  font-size: 24px;
  width: 18px;
  height: 18px;
  overflow: hidden;
}

.faq-con-main .accordion-button:not(.collapsed)::after {
  content: '+';
  transform: rotate(135deg);
  margin-right: 0px;
}
.pinverification-input { letter-spacing:41px;  text-indent:5px; 
 /* background-image: url(../src/assets/box-bg-3.png); background-repeat: repeat-x; background-size:48px 100px; */
}

.pinverification-input::-webkit-input-placeholder { letter-spacing:38px;}
.pinverification-input:-ms-input-placeholder {  letter-spacing: 38px;}
.pinverification-input::placeholder {  letter-spacing: 38px;}

.btn-danger {
  background: red;
}

.btn-danger:hover,
.btn-danger:focus {
  background-color: rgb(187, 0, 0);
}

.btn-link,
.btn-link-white {
  color: #fff;
}

.btn-link:hover,
.btn-link:focus,
.btn-link-white:hover,
.btn-link-white:focus {
  color: #fff;
  border-color: white;
}

.btn-link-black {
  color: #000;
}

.btn-link-black:hover,
.btn-link-black:focus {
  color: #000;
  border-color: #000;
}

.navbar {
  margin-bottom: 50px;
}

.navbar-right-con .searchicon {
  margin: 0px 0px 0px 10px; padding: 0 5px !important; top:18px; position: relative;
}

.navbar-right-con .dropdown a {
  width: 100%;
  margin: 0px auto;
}

.act-box-main-con {
  border: none;
}
.scrollableDiv { margin-left: -15px; margin-right: -15px;}
.user-active-out-con {
  clear: both;
  float: left;
  line-height: normal;
  display: flex;
  flex-wrap: wrap;
  width: 102%;
  margin-left: -15px; margin-right:-15px;
}
.btn-pink{background:#ef5c64!important; color: #fff;}
.btn-blue{background:#42bbd5!important; color: #fff;}
.btn-brown{background:#dcb421!important; color: #fff;}
.btn-green{background:#25dc22!important; color: #fff;}
.btn-black{background:#000!important; color: #fff;}
.btn-red{background:red!important; color: #fff;}
.user-active-out-con .act-box-main-con { position: relative; max-width:31%; display: flex; text-align: left;  margin: 10px 15px;  background: #1a1919; }
.infinite-scroll-component { overflow: hidden;}
.text-light-1 { font-weight: 400;}
.homepage-main-con .home-inn-con-1 h1.text-light-1 {font-weight:400;}
.app-con-con-welcome {float: none;  display: inline-block;  font-size:18px;}
.app-con-con-welcome-con {font-size:36px;  font-weight:400;  margin: 0px auto 20px;  text-transform: none;}
.app-con-con-welcome-con-h1 { display: inline-block;}
.app-con-con-welcome .icon-box img { max-height:40px;}

.link-all-activity { float: right; margin-right: 25px;}

@media (min-width:1921px) {
  .user-active-out-con .act-box-main-con { width: auto;  margin: 15px 1%;  max-width: none;   flex-basis:23%; }
  /*.homefreeactivity .user-active-out-con .act-box-main-con {  width:31%;  }*/
  .container { max-width:98vw; }
  .form-main-con-1, .form-main-con-1.container { max-width:500px;}
}
@media (min-width:3001px) {
  .user-active-out-con .act-box-main-con {
    width: auto;
    margin: 15px 1%;
    max-width: none;
    flex-basis: 23%;
  }
}
@media (max-width:1920px) {
  .user-active-out-con .act-box-main-con {  width:23%;  margin:15px 1%;  }
  /*.homefreeactivity .user-active-out-con .act-box-main-con {  width:31%;  }*/
  .container { max-width:1600px; }
  .form-main-con-1, .form-main-con-1.container { max-width: 400px;}
}

@media (max-width:1780px) {
  .homefreeactivity .act-box-main-con .act-name { min-height: 65px;}
  .container { max-width:96vw; }
}
@media (max-width:1280px) {
.user-active-out-con .act-box-main-con {  width:48%; max-width: 48%;  margin:15px 1%;  }
}
@media (max-width:1024px) {
  .homepage-main-con .home-inn-con-1 h1 {
    font-size: 28px;
    line-height: 42px;
  }

  .faq-con-main-home-con {
    padding: 50px 0px;
  }

  .user-active-out-con .act-box-main-con {
    width: 48%; max-width: 48%;
    text-align: left;
    margin: 10px 1%;
  }
}
@media (min-width: 992px){
.navbar-dark .navbar-nav .nav-link { color: #fff;}
.navbar-expand-lg .navbar-nav .dropdown-menu { right: 0; left: auto; white-space:nowrap; }
}
@media (max-width:767px) {
  .user-active-out-con {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  .user-active-out-con .act-box-main-con {
    width: 98%; max-width:98%;
    text-align: left;
    margin: 10px 1%;
  }
}

.act-box-main-con .con-data {
  font-size: 14px;
  display: block;
  color: #fff;
}

.act-box-main-con .con-data .fa {
  color: #fff;
}

.act-box-main-con .act-price {
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 18px;
  width: 70px;
  text-align: center;
  padding: 10px;
  border-radius: 0 0px 10px;
  z-index: 99;
  display: none;
}

.act-box-main-con .act-price.paid {
  display: block;
}

.act-box-main-con .act-price .fa {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: -3px 2px 0px -5px;
}

.act-box-main-con .act-img-con {
  position: relative;
  overflow: hidden;
  min-height: 170px;
  color: #000;
  background-color: #fff;
  width: 100%;
}

.act-box-main-con .act-img-con img {
  z-index: 0;
  height: 100%;
  width: 100%;
  border: none;
  border: solid 0px #fff;
  position: relative;
  transition: transform 0.5s;
  border-radius: 0px;
  /* Animation */
}

.act-box-main-con .act-img-con:hover img {  transform: scale(1.06);}
.all-activity-demo-page .act-img-con img { max-height: 195px;}
.all-activity-demo-page .act-box-main-con {overflow: hidden; border-radius: 0px;}
.all-activity-demo-page .act-box-main-con .act-info-con { display: none; align-items: center; justify-content: center; position: absolute; padding: 10px; bottom: 0; 
  height:100%; width: 100%; text-align: center;
  background: rgb(0, 0, 0, 0.9);
  -webkit-animation: acthoverout 0.5s;
  animation: acthoverout 0.5s;
}
.all-activity-demo-page .act-box-main-con:hover .act-info-con { display: flex;
-webkit-animation: actbottomtop 0.5s;
  animation: actbottomtop 0.5s; 
}

 
@-webkit-keyframes actbottomtop { 0%   { opacity: 0;} 100% { opacity: 1;} }
@keyframes actbottomtop { 0%   {opacity: 0;} 100% { opacity: 1;}}

@-webkit-keyframes acthoverout { 0% {opacity: 1;}  100% {  opacity: 0; display: none;}}
@keyframes acthoverout  { 0% {opacity: 1;}  100% {  opacity: 0; display: none;}}

.all-activity-demo-page .act-box-main-con .act-name {   text-overflow:ellipsis; overflow: hidden;}

.act-box-main-con .act-category {
  clear: both;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  color: #f6f6f6;
}

.act-box-main-con .act-category * {
  color: #f6f6f6;
}

.act-box-main-con .act-category .fa {
  font-size: 12px;
  font-weight: 300;
  margin: 0px 5px;
  line-height: 20px;
}

.act-box-main-con .act-name {
  font-size: 24px;
  font-weight: 400;
  margin: 5px 0px;
  line-height: normal;
}
.act-box-main-con .act-chapter {
  font-size: 18px;
  font-weight: 300;
  text-transform: none;
}

.act-box-main-con .act-grade {
  font-size: 16px;
  margin: 5px 0px;
  font-weight: 400;
}

.act-box-main-con .act-lang {
  font-size: 16px;
  font-weight: 400;
  clear: none;
  display: inline-block;
  margin: 0px 10px;
}

.act-box-main-con .act-lang *,
.act-box-main-con .act-lang .fa {
  margin: -2px 5px 0px 5px;
  color: #fff;
}

.icon-big {
  font-size: 24px;
  line-height: normal;
}

.act-box-main-con .act-rat-icon-con {
  position: relative;
  padding: 5px 30px 5px;
}

.act-box-main-con .act-rat-icon-con * {
  color: #666;
}

.act-box-main-con .act-rat-icon-con .icon-big {
  font-size: 14px;
  margin: -4px 10px 0px -30px;
  line-height: normal;
}

.act-box-main-con .act-rat-icon-con .val-con {
  min-width: 20px;
  display: inline-block;
}

.act-box-main-con .act-buy-btn-con {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.act-box-main-con .act-buy-btn-con .btn {
  display: block;
}

.act-box-main-con .act-buy-btn-con.paid .btn-danger {
  display: block;
}

.act-box-main-con .act-buy-btn-con.paid .btn-danger {
  display: none;
}

.act-box-main-con .act-buy-btn-con.false .btn-warning {
  display: none;
}

.act-box-main-con .act-buy-btn-con.false .btn-danger {
  display: block;
}
.webview-con { width:78vw; height:85vh;}
.text-white,
.text-white:visited {
  color: #fff;
}
.noborder {
  border: none;
}
.noborder-bottom {
  border-bottom: none;
}
.noborder-top {
  border-top: none;
}
.link-only, .nobtn{
  background: no-repeat;
    outline: none;
    border: none;
    width: auto;
  }
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.text-red,
.text-red:visited,.form-main-con-1 p.text-red {
  color: red;
}

.text-black,
.text-black:visited {
  color: #000;
}

.text-blue,
.text-blue:visited {
  color: #214463;
}
.text-yellow,
.text-yellow:visited,
.form-main-con-1 p.text-yellow {
  color:yellow;
}
.text-gray-1,
.text-gray-1:visited {
  color: #666;
}

.user-info-box {
  clear: both;
  background: #fff;
  padding: 10px 10px 10px 100px;
  margin: 10px auto;
  color: #000;
  min-height: 100px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

.user-info-box .icon-delete,
.user-info-box .icon-edit {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #000;
  cursor: pointer;
}

.user-info-box .icon-delete {
  top: 60px;
}

.user-info-box .user-image {
  position: absolute;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  overflow: hidden;
  left: 10px;
  position: absolute;
}

.user-info-box .user-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  background-color:#2bbd76;
  color: #fff;
}

.user-info-box .user-name {
  width: 100%;
  font-size: 24px;
  text-transform: uppercase;
}

.user-info-box .user-grade,
.user-info-box .user-language {
  width: 100%;
  font-size: 16px;
  color: #666;
  clear: both;
  display: block;
}

.min-height {
  min-height: 50vh;
  color: #fff;
  background: #000;
  padding: 50px; padding-top: 50px!important; padding-bottom: 50px!important;
  overflow:auto;
}
.bookfaqminheight {    
/*  height: auto;
  clear: both;
  float: left;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  */
  height: 100%;
   
}
.bookfaqminheight-inncon {    
    height: auto;
    max-height: 1000px;
    clear: both;
    float: left;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

/* Start Popupbox style */
.popup-outer-con {
  position: fixed;
  z-index: 99999;
  width: 100vw !important;
  height: 100vh;
  overflow: auto;
  background: #000;
  padding: 100px 50px;
  left: 0vw;
  right: 0vw;
  top: 0vh;
  max-width: 100vw !important;
  box-sizing: border-box;
}

.popup-outer-con:before {
  content: "";
  background: #20214252;
  height: 100vh;
  z-index: -2;
  position: absolute;
  left: 0vw;
  right: 0vw;
  top: -20vh;
  bottom: 0vh;
  display: none;
}

.popup-outer-con .popup-header {
  cursor: pointer;
  z-index: 9999;
  padding: 0px 10px;
  line-height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  background: red;
  color: #fff;
}

.popup-outer-con .popup-body {
  background: #000;
  clear: both;
  float: left;
  width: 100%;
  position:
    absolute;
  top: 0px;
  left: 0px;
}

.viewdetailinfobox-con {
  text-align: left;
  width: 90%;
  position: absolute;
  left: 10px;
  top: 15px;
  line-height: normal;
}

#viewdetailinfobox {
  position: absolute;
  top: 5px;
  left: 70px;
}

#viewdetailinfobox span {
  padding: 5px;
  margin: 0px 0px 0px 10px;
  text-align: left;
  color: #fff;
}

iframe::-webkit-scrollbar-track {
  background: #333;
}

iframe::-webkit-scrollbar-thumb {
  background: red;
  cursor: pointer;
}

.scrollableDiv::-webkit-scrollbar-track {
  background: #333;
}

.scrollableDiv::-webkit-scrollbar-thumb {
  background: #666;
  cursor: pointer;
}

.iframe-box-outer-con {
  /* display: block; width:90vw; height:75vh; margin:70px auto 50px; border: none; outline: none;*/
  position: absolute;
  top: 60px;
  left: 5%;
  bottom: 0;
  right: 5%;
  width: 90%;
  height: auto;
  border: none;
  box-sizing: border-box;
}

.iframe-box-inn-con {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
}

.iframe-responsive {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 99%;
  height: 90%;
  border: none;
}

.homevideocon {
  width: 100%;
  height: 40vw;
  margin-top: -7%;
  margin-bottom: -7%;
}

.homevideocon.banner-top {
  width: 100%;
  height: 400px;
}

.video-bg-con-home {
  width: 100%;
  overflow: hidden;
  position: relative;
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
  /* Adjust as needed */
}
.search-main-con-1 {clear: both;
  display: block;
  margin:10px 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 0px;
  position: relative;}
.search-con-main {
  clear: both; float: left;
  margin:0px 0px 0px 0px;
  width:100%;
  box-sizing: border-box;
  padding: 0px 0px;
  position: relative;
  overflow: hidden;
}

.search-con-main .search-btn {
  color: #fff;
  padding: 10px 20px;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  border-radius: 0;
}

.search-con-main .searchfield {
  background: #333;
  color: #fff;
  width: 100%;
  font-size: 1.5em;
  height: auto;
  padding:5px 20px;
}

.user-active-out-con .act-box-main-con {
  opacity: 1;
    transition: opacity 2s ease-in;
}

 

@media (max-width:1024px) {}

@media (max-width:767px) {}

/* End Popupbox style */


/* Start Style Loading Spinner Style */
.loader {
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* End Style Loading Spinner Style */

/* Start Contact Page Style */
.contact-iframe-con-out {
  border-radius: 10px;
  padding: 40px;
}

.contact-iframe-con-out .row {
  margin: 0;
  padding: 0px;
}

.address-item {
  padding: 0px;
  background-color: #333;
  /* border-radius: 10px; */
  text-align: left;
  position: relative;
  float: left;

  display: flex;
  min-height: 110px;
  align-items: center;
}

.address-item .address-icon {
  background: #ff002a;
  height: 80px;
  width: 65px;
  color: #fff;
  line-height: 80px;
  position: relative;
  margin: 10px auto 15px;
  font-size: 26px;
  text-align: center;
  float: left;
  margin: 10px;
}

.address-item .address-text .contact-title {
  font-size: 22px;
  margin: 0 0 0px;
  font-weight: 400;
  color: #fff;
}

.contact-frame {
  width: 100%;
  height: 450px;
  padding: 10px 0px 0;
}

.address-item .address-text {
  float: left;
  margin: 10px 10px;
}

.address-item .address-text ul,
.address-item .address-text li {
  margin: 0;
  padding: 0;
  line-height: 30px;
}

.address-item .address-text ul li a,
.address-item .address-text ul li a:visited,
.address-item .address-text ul li a:focus {
  color: #fff;
}

@media (max-width:768px) {
  .min-height {
    padding: 15px;
  }

  .contact .d-flex.bd-highlight {
    flex-direction: column;
  }

  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
  .form-main-con-1, .form-main-con-1.container {max-width: 400px; }
}

/* End Contact Page Style */


/* Start Team page Style */
.team-main-con {
  display: block;
  margin: 50px auto;
}

.team-inn-con {
  padding:20px;
  margin: 15px auto;
  border: solid 2px #111;
  background-color: #303030;
}

.team-inn-con:hover {
  background: #111;
}

.team-img-top {
  margin: 15px auto;
  display: block;
  width: 400px;
  height: 300px;
  object-fit: cover;
  max-width: 100%;
}

.team-inn-con .card-title {
  margin-bottom:10px;
  border-bottom: solid 0px #111;
  padding-bottom:10px;
}

.filtermenu-con-main {
  padding: 15px;
}

.filtermenu-con-main * {
  color: #fff !important;
}

.filtermenu-con-main .option-box {
  display: inline-block;
  width: auto;
  margin: 10px;
}

form .invalid-feedback.form-error, .invalid-feedback.form-error {
  line-height: 32px;
  padding-left: 15px;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.04em;
  display: block;
  background-color: #303030;
  color: #ff0000;
}

/* Start How to use guide Style */
.howtoguide-con-main {
  margin: 0px 0px 50px;
}
.howtoguide-main-con { max-width:100%;}
.howtoguide-main-con ol {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.howtoguide-main-con li {
  margin: 3px 0px;
}

/* Start home call to action FREE options Style */
.home-call-action-con {
  background-color: #303030;
  margin: 50px auto -50px 0px;
  padding: 25px 0px;
  display: block;
  clear: both;
  box-sizing: border-box;
  float: left;
  width: 100%;
}

.home-call-action-con-top {
  background-color: #000;
  margin:20px auto -20px 0px;
  padding: 15px 0px;
  display: block;
  clear: both;
  box-sizing: border-box;
  float: left;
  width: 100%;
}

.chat-max-height { height:400px; max-height:400px;  overflow-y: scroll; position: relative;}
.home-call-action-con-top p { margin: 0 auto;}

.form-main-con-1.fullwidth {
  max-width: 1320px;
}

.google-map-side {
  margin: 50px 0px;
  width: 100%;
  height: 70%;
  border: none;
}
.verificationbox-out-con {
  background-color: #111;
  position: absolute;
  height: 100%; top:0px;
  width: 100%; bottom: 0px;
  left: 0px; right: 0px; z-index: 99999;
}
.verificationbox-con { padding:15vh 30px; width:100%; max-width: 100%;   position: relative;  height: 100%;  background-color: #111;}






/* start   Chta ++++++++++++++++++++++++++++*/
.card {  background-color: #000;}
.symbol.symbol-45px.symbol-fixed .symbol-label, .symbol.symbol-45px .symbol-label, .symbol.symbol-45px>img {
  height: 45px;
  width: 45px;
}
.symbol.symbol-circle, .symbol.symbol-circle .symbol-label, .symbol.symbol-circle .symbol-label:after, .symbol.symbol-circle:after, .symbol.symbol-circle>img, .symbol.symbol-circle>img:after {
  border-radius: 50%;
}
.symbol.symbol-35px.symbol-fixed .symbol-label, .symbol.symbol-35px .symbol-label, .symbol.symbol-35px>img {
  height: 35px;
  width: 35px;
}
.fs-7 {
  font-size: 11px!important;
}
.text-gray-900 {
  color: #fff!important;
}
.text-gray-400 {
  color: #2c2d35!important;
}

@media (min-width: 1200px){
.w-xl-400px {    width: 400px!important;}
}
.bg-light-info { background-color: #292636!important; color: #fff!important;}
.bg-light-primary{ background-color: #21262e!important; color: #fff!important;}
.chat-body button { background-color: #fff;   opacity: 1;
  border-radius: 4px;
  width: 40px;
  height: auto;
  line-height: normal;
  padding: 5px 0px 3px;}
.chat-body button::after { content: "\f119"; color:red; font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;}
.chat-body button:hover { background-color: red;   transition: transform 0.5s;}  
.chat-body button:hover:after { color: #fff;   transition: transform 0.5s;}
.message-body { padding-right: 10px;}
.bi-power::before {
  content: "\f4ff";
}
/* End chat  */